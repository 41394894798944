<template>
<div style="text-align: left">
  <Card style="height:100%">
    <div class="search-con search-con-top">
    <Input clearable placeholder="输入名称 搜索" class="search-input" v-model="queryinfo.name"
           @on-enter="namechange"
           @on-change="namechange"/>
    <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
    <slot name="new_btn">
      <Button type="primary" class="search-btn"  @click="showaddreceivermodel">新建</Button>
    </slot>
  </div>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" ></Table>
    </div>
    <br />
    <template>
      <Page :current="queryinfo.page_num"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Drawer
        :closable="false"
        v-model="showaddreceiver"
        title="添加告警集成"
        width="800">
      <Form ref="formValidate1" :model="formDynamic" :rules="ruleformDynamic" :label-width="80">
<!--      <Form ref="formValidate" v-model="formDynamic" :rules="ruleformDynamic" :label-width="80">-->
        <FormItem label="名称" prop="name" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.name" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="类型" prop="type" :label-width="80" style="width: 77%">
          <Select
              v-model="formDynamic.type"
              placeholder="类型">
            <Option v-for="type in typelist" :value="type.value" :key="type.value">{{ type.label }}</Option>
          </Select>
        </FormItem>
        <FormItem label="恢复时间" prop="auto_recover_duration" :label-width="80" style="width: 77%">
          <InputNumber v-model="formDynamic.auto_recover_duration" placeholder="自动恢复时间" style="width: 100%"></InputNumber>
        </FormItem>
        <FormItem label="描述" prop="desc" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.desc" type="textarea" placeholder="请输入描述信息" :autosize="{minRows: 6}"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="addform('formValidate1')">确定</Button>&nbsp;
          <Button @click="addcancel">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
    <Drawer
        :closable="false"
        v-model="showupdatereceiver"
        title="修改告警集成"
        width="800">
      <Form ref="formValidate" :model="updateformDynamic" :rules="ruleformDynamic" :label-width="80">
        <FormItem label="名称" prop="name" :label-width="80" style="width: 77%">
          <Input v-model="updateformDynamic.name" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="类型" prop="type" :label-width="80" style="width: 77%">
          <Input v-model="updateformDynamic.type" placeholder="请输入类型" disabled></Input>
        </FormItem>
        <FormItem label="恢复时间" prop="auto_recover_duration" :label-width="80" style="width: 77%">
          <InputNumber v-model="updateformDynamic.auto_recover_duration" placeholder="自动恢复时间" style="width: 100%"></InputNumber>
        </FormItem>
        <FormItem label="描述" prop="desc" :label-width="80" style="width: 77%">
          <Input v-model="updateformDynamic.desc" type="textarea" placeholder="请输入描述信息" :autosize="{minRows: 6}"></Input>
        </FormItem>
        <FormItem>
          <Button @click="updateform('formValidate')" type="primary">保存</Button>&nbsp;
          <Button @click="updatecancel">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
  </Card>
</div>
</template>

<script>
import {get,post,del,patch,getAlertAPIUrl} from "@/api/http.js";
export default {
  name: "integrations",
  data() {
    return {
      showaddreceiver: false,
      showupdatereceiver: false,
      queryinfo: {
        page_num: 1,
        page_size: 10,
        name:"",
      },
      typelist: [
        {value:'zabbix',label:"zabbix"},
        {value:'custom',label:"custom"},
      ],
      data: [],
      total: 0,
      formDynamic: {
        name: '',
        type: '',
        auto_recover_duration: 60
      },
      updateformDynamic: {},

      ruleformDynamic: {
        name: [
          { required: true, message: 'The name cannot be empty'}
        ],
        type: [
          { required: true, message: '类型不能为空'}
        ],
      },
      pagesizeopts: [10, 30, 50, 100],
      columns: [
        // {type: 'selection', width: 60, align: 'center'},
        {title: '名称', key: 'name'},
        {title: '地址', key: 'id',
          render: (h,{row}) =>{
            return h('div',this.get_path(row.id))
          }
        },
        {title: '状态', key: 'status',
          render: (h,{row}) =>{
            return h('div',this.is_status(row.status))
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 180,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              // h('a', {
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.showupdatedrawer(params.row)
              //     }
              //   }
              // }, '编辑 |'),
              h('a', {
                on: {
                  click: () => {
                    this.deleteIntegration(params.row)
                  }
                }
              }, '删除 |'),
              h('a', {
                on: {
                  click: () => {
                    this.changestate(params.row)
                  }
                }
              }, this.getStatusAction(params.row)),
            ]);
          }
        }
      ],
    }
  },
  methods: {
    getStatusAction(ig) {
      return ig.status===0 ? " 禁用" : " 启用";
    },
    timestampToTime (cjsj) {
      var date = new Date(cjsj) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y+M+D+h+m+s
    },
    time_str(timestamp){
      return (this.timestampToTime (timestamp))
    },
    is_status(value) {
      if (value===0){
        return '启用'
      } else {
        return '禁用'
      }
    },
    get_path(fields){
      const base_url = "https://ppalerts.paigod.work:19090/api/v1/"
      return base_url + fields
    },
    showaddreceivermodel () {
      this.formDynamic= {
        name:'',
        type:'',
        auto_recover_duration: 60
      }
      this.showaddreceiver = true
    },
    namechange(){
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getintegrations(this.queryinfo)
    },
    changepage (page) {
      this.queryinfo.page_num = page
      this.getintegrations(this.queryinfo)
    },
    changepagesize(pagesize) {
      this.queryinfo.page_size = pagesize
      this.getintegrations(this.queryinfo)
    },
    addcancel () {
      this.showaddreceiver = false
    },
    updatecancel() {
      this.showupdatereceiver = false
    },

    disbalestatue (ids) {
      const url = getAlertAPIUrl(`/api/v1/integrations?action=status&disable=${ids}`);
      patch(url).then((resp)=>{
        if (resp["code"] === 200){
          this.$Message.success("禁用成功")
          this.getintegrations(this.queryinfo)
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },

    enablestatue (ids) {
      const url = getAlertAPIUrl(`/api/v1/integrations?action=status&enable=${ids}`);
      patch(url).then((resp)=>{
        if (resp["code"] === 200){
          this.$Message.success("启用成功")
          this.getintegrations(this.queryinfo)
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },

    changestate(row) {
      //const ids = {ids:row.id}
      if (row.status === 0) {
        this.disbalestatue(row.id);
      } else {
        this.enablestatue(row.id);
      }
    },

    addreiver(data){
      const url = getAlertAPIUrl("/api/v1/integrations");
      post(url,data).then((resp)=>{
        if (resp.code >= 200 && resp.code < 300){
          //this.$Message.success('Success!');
          this.getintegrations(this.queryinfo)
        } else {
          this.$Message.error("请稍后再试");
        }
      }).cache((e)=>{
        this.$Message.error("请稍后再试");
      })
    },

    updateform (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addreiver(this.updateformDynamic)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },
    addform (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addreiver(this.formDynamic)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },
    showupdatedrawer(row){
      this.showupdatereceiver = true
      this.updateformDynamic = row
    },
    deleteIntegration(row) {
      if(!confirm("确定删除?")) {
        return;
      }
      const url = getAlertAPIUrl(`/api/v1/integrations/${row.id}`);
      del(url).then((resp)=>{
        if (resp["code"] === 200){
          this.getintegrations(this.queryinfo);
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },
    getintegrations (queryinfo) {
      this.showaddreceiver = false
      this.showupdatereceiver= false
      const url = getAlertAPIUrl("/api/v1/integrations");
      get(url,queryinfo).then((resp)=>{
        if (resp["code"] >= 200 && resp["code"] < 300){
          this.total = resp["data"]["total"]
          if (resp["data"]["items"]) {
            this.data = resp["data"]["items"]
          } else {
            this.data = []
          }
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },

  },
  created() {
    this.getintegrations(this.queryinfo)
  },
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
